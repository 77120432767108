import { IPrediction } from "../../app/types/predictions";
import { mock } from "../mock";

function createData({ symbol, prediction, totalPredictions, sentiment, symbolLabel, targetDate, submissionDate, name }: IPrediction): any {
    return { symbol, prediction, totalPredictions, sentiment, symbolLabel, targetDate, submissionDate, name };
}

const predictions: Array<IPrediction> = [
    createData({ symbol: 'AAPL', symbolLabel: undefined, prediction: 208.14, totalPredictions: 7745905, sentiment: "really_bullish", targetDate: "01/15/24", submissionDate: "11/21/23", name: "test" }),
    createData({ symbol: '', symbolLabel: 'GEM', prediction: 208.14, totalPredictions: 7745905, sentiment: "bullish", targetDate: "01/15/24", submissionDate: "11/21/23", name: "test" }),
    createData({ symbol: 'AMZN', symbolLabel: undefined, prediction: 208.14, totalPredictions: 7745905, sentiment: "no_trend", targetDate: "01/15/24", submissionDate: "11/21/23", name: "test" }),
    createData({ symbol: 'AMZN', symbolLabel: undefined, prediction: 208.14, totalPredictions: 7745905, sentiment: "bearish", targetDate: "01/15/24", submissionDate: "11/21/23", name: "test" }),
    createData({ symbol: 'AMZN', symbolLabel: undefined, prediction: 208.14, totalPredictions: 7745905, sentiment: "really_bearish", targetDate: "01/15/24", submissionDate: "11/21/23", name: "test" }),
    createData({ symbol: 'MSFT', symbolLabel: undefined, prediction: 208.14, totalPredictions: 7745905, sentiment: "bullish", targetDate: "01/15/24", submissionDate: "11/21/23", name: "test" }),
];



mock.onGet(new RegExp(`/api-auth/submissions`)).reply(() => {
    return [200, predictions]
});

mock.onGet(new RegExp(`/api-auth/user/[a-zA-Z0-9]+/submissions`)).reply(() => {
    return [200, predictions]
});
